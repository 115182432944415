<template>
  <b-card>
    <div style="margin: 10px;font-size: 15pt;font-weight: bold;">
      TOTAL DE ASSOCIADOS: {{ associatedsQuantity }}
    </div>
    <div class="overflow-auto">
      <b-table
        id="my-table"
        ref="refUserListTable"
        class="position-relative"
        :items="filter"
        :fields="tableColumns"
        :current-page="currentPage"
        :per-page="perPage"
        responsive
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-asc.sync="isSortDirDesc"
      >
        <!-- Column: Actions
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="edit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteSpeakerEvent(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template> -->
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">A partir de {{ dataMeta.from }} para {{ dataMeta.to }} de {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-card>
</template>

<script>
import useList from './useList'
import AssociatedServices from './AssociatedsServices'

export default {
  setup() {
    const {
      fetchUsers,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList()

    return {

      fetchUsers,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalAssociateds: 0,
      associateds: [],
      perPageOptions: [10, 25, 50, 100, 200, 300, 400, 500],
      selectValue: '',
      filter: [],
      searchValue: null,
      rowData: {
        id: null,
        name: null,
        email: null,
        phone: null
      },
      tableColumns: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Nome',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'phone',
          label: 'Telefone',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    rows() {
      return this.filter.length
    },
  },
  mounted() {
    AssociatedServices().getByYear().then((res) => {
      res.associateds.forEach((associated) => {
        if (associated.user) {
          this.filter.push({
            id: associated.id,
            name: associated.user.name,
            email: associated.user.email,
            phone: associated.user.phone
          })
        }

        if (!associated.user) {
          this.filter.push({
            id: associated.id,
            name: 'Não informado',
            email: 'Não informado',
            phone: 'Não informado'
          })
        }
      })
      this.associatedsQuantity = res.quantity
    })
  }
}
</script>

<style>

</style>
